import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  const featurePages = posts.filter(
    p => p.node.fields.slug !== "/the-movement-challenge/"
  )

  return (
    <Layout title={"Seraphina"}>
      <SEO
        title="Seraphina"
        keywords={[
          "model",
          "connection coach",
          "dance coach",
          "nyc",
          "new york",
        ]}
      />
      <figure className="kg-image-card kg-large">
        <Img
          fluid={data.seraphina.childImageSharp.fluid}
          className="kg-image-smaller"
        />
      </figure>
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            I facilitate connection—within individuals and between individuals.
            I guide people to explore their relationships with mind and body,
            move through their vulnerabilities, and find their ultimate goal:
            greater joy.
          </h2>
        </header>
      )}
      <div className="testimonials-container">
        {data.testimonials.edges.map(({ node }) => (
          <div className="testimonial" key={node.frontmatter.title}>
            <a
              className="testimonial-headshot"
              href={node.frontmatter.website}
              target="_blank"
            >
              <Img
                fluid={{
                  ...node.frontmatter.thumbnail.childImageSharp.fluid,
                  aspectRatio: 1,
                }}
                imgStyle={{ borderRadius: "50%" }}
              />
              <div className="testimonial-name">{node.frontmatter.title}</div>
            </a>
            <blockquote className="testimonial-blockquote">
              <p>{node.rawMarkdownBody}</p>
            </blockquote>
          </div>
        ))}
      </div>
      <div className="row page-head">
        <h2 className="page-head-title">Working with Seraphina</h2>
      </div>
      <div className="post-feed">
        {featurePages.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      <div className="post-feed">
        {featurePages.map(({ node }) => {
          postCounter++
          return <p className="post-card-new">{node.frontmatter.description}</p>
        })}
      </div>
      <div className="row page-head">
        <h2 className="page-head-title" id="about">
          About Seraphina
        </h2>
      </div>
      <figure
        className="kg-image-card kg-large"
        style={{ marginBottom: "1em" }}
      >
        <Img
          fluid={data.seraphinaa.childImageSharp.fluid}
          className="kg-image-smaller"
        />
      </figure>

      <div className="row">
        <div className="col-12">
          <p>
            Born under a tree in Woodstock, NY, Seraphina Mallon-Breiman is now
            based in New York City with her calico cat. After living the
            majority of her young adulthood as an Unschooler, Seraphina spent
            her undergrad studying Sociology and Anthropology in Vermont and is
            passionate about the intersection of the human experience, somatic
            arts and embodiment, healing trauma, play, social justice, design
            and sustainability. She is currently completing her Clinical MSW at
            Hunter College’s Silberman School of Social Work.
          </p>
          <p>
            Seraphina is known for her social intelligence and creativity,
            unstoppable positivity and motivation, and for manifesting
            long-lasting, deeply fulfilling relationships and projects. With a
            unique perspective on human behavior and connection, Seraphina
            combines her ability to problem solve and think outside of the box
            to design and deliver unparalleled human experiences.
          </p>
          <p>
            You can also find Seraphina working with{" "}
            <a href="https://info.ggc.nyc/">The Great Gotham Challenge</a>
            running immersive clue-based scavenger hunts, working with{" "}
            <a href="https://www.unitycollective.co/">The Unity Collective</a> a
            project she co founded combating racial injustice in America, or
            presenting as a Guest Speaker on her upbringing with alternative
            education and journey as an entrepreneur. Ask her about her
            experiences living and working internationally in 7 different
            countries or introduce her to your favorite local hike!
          </p>
        </div>
      </div>
      <figure className="kg-image-card kg-large">
        <Img fluid={data.speaking.childImageSharp.fluid} className="kg-image" />
      </figure>
      <div className="row">
        <div
          className="col-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <em style={{ margin: "0 auto" }}>
            Pictured speaking for a group of teens at The Brooklyn Collaborative
            School.
          </em>
        </div>
      </div>

      <div className="row page-head">
        <h2 className="page-head-title" id="events">
          Events
        </h2>
      </div>
      <div className="row">
        <div className="col-12 responsive-iframe-container">
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23f8f8f8&amp;ctz=America%2FNew_York&amp;src=cXVrODUzdjVlZWI2amE5MmtlZmw3Yjh0NWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23F6BF26&amp;showTabs=1&amp;showPrint=0&amp;showCalendars=0&amp;showDate=1&amp;showTitle=0&amp;mode=AGENDA"
            style={{ "border-width": 0 }}
            width="800"
            height="600"
            frameborder="0"
            scrolling="no"
          />
        </div>
      </div>
      <div className="row page-head">
        <h2 className="page-head-title" id="connect">
          Want to connect with Seraphina? Send her an email:
        </h2>
      </div>
      <div className="row">
        <div className="col-12">
          <form
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact-seraphina"
          >
            <div className="row gtr-uniform">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact-seraphina" />
              <div className="col-4 col-12-xsmall">
                <label>
                  Name
                  <input type="text" name="name" id="name" />
                </label>
              </div>
              <div className="col-4 col-12-xsmall">
                <label>
                  Email
                  <input type="email" name="email" id="email" />
                </label>
              </div>
              <div className="col-4 col-12-xsmall">
                <label>
                  Subject
                  <input type="text" name="subject" id="subject" />
                </label>
              </div>
              <div className="col-12">
                <label>
                  Message
                  <textarea name="message" id="message" rows="5" />
                </label>
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <button type="submit" className="primary">
                      Send
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    seraphina: file(relativePath: { eq: "new-headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seraphinaa: file(relativePath: { eq: "about-headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    speaking: file(relativePath: { eq: "speaking.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/testimonials/.*.md$/" } }
      sort: { fields: [frontmatter___title], order: DESC }
    ) {
      edges {
        node {
          rawMarkdownBody
          frontmatter {
            title
            website
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/pages/.*.md$/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
